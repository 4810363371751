import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "../../services/api";

export const fetchLeadData = createAsyncThunk("fetchLeadData", async () => {
  const response = await getApi("api/lead/");
  return response.data;
});

const leadSlice = createSlice({
  name: "leadData",
  initialState: {
    data: [],
    isLoading: false,
    error: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLeadData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = "";
      })
      .addCase(fetchLeadData.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.error.message;
      });
  },
});

export default leadSlice.reducer;
