import { createSlice } from "@reduxjs/toolkit";

const getInitialUser = () => {
  const user = window.localStorage.getItem("user");
  if (user && user !== "undefined") {
    return JSON.parse(user);
  }
  return {};
};
const initialState = {
  user: getInitialUser(),
};

const localSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      // You can also update localStorage here if needed
    },
    clearUser: (state) => {
      state.user = null;
      // You can also update localStorage here if needed
    },
  },
});

export const { setUser, clearUser } = localSlice.actions;

export default localSlice.reducer;
