import { useState, useEffect } from "react";
import { getApi } from "services/api";

const { Select } = require("@chakra-ui/react");
const getStyle = (value) => {
  if (value.length > 0 && value !== "N/A") {
    return "completed";
  } else {
    return "pending";
    // return "onHold";
  }
};
const UserSelectDD = ({ props }) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    getApi("api/user/").then((res) => {
      setUsers(res.data?.user);
    });
  }, []);
  return (
    <Select
      defaultValue={"active"}
      disabled={props.disabled}
      className={getStyle(props.value)}
      onChange={(e) => props.setAsignee(props.name, e.target.value)}
      value={props.value}
      style={{ fontSize: "14px" }}
    >
      <option value="">-</option>
      {users?.map((item) => {
        return (
          <option key={item._id} value={item._id}>
            {item.firstName} {item.lastName}
          </option>
        );
      })}
    </Select>
  );
};

export default UserSelectDD;
